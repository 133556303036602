<script>
import appConfig from '@/app.config'
import store from '@/state/store'

export default {
	page: {
		title: 'change number',
		meta: [{ name: 'description', content: appConfig.description }],
	},
	data() {
		return {
			phoneData: {
				isValid: false
			},
			formattedPhone: "",
			inputError: false,
			errors: '',
			fullscreenLoading: false,
			loading: false,
			isOTP: false,
			formData: {
				number1: '',
				number2: '',
				number3: '',
				number4: ''
			},
			type: 'alert-danger',
			formDataNumber: {
				phone: "",
			},
			rules: {
				phone: [
					{
						required: true, message: 'Please phone number is required.', trigger: 'change'
					},
					{
						pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/g, message: "Enter a valid phone number", trigger: "change"
					},
					{ min: 10, max: 15, message: `Length should be greater than 11`, trigger: 'blur' }
				],
			}
		}
	},
	computed: {
		currentUser() {
			return store.getters['auth/currentUser'] ? store.getters['auth/currentUser'].phone : ""
		}
	},
	methods: {
		handleCountryChange(payload) {
			this.phoneData = payload
			return this.phoneData
		},
		handleOneChange() {
			this.$refs.number2.focus();
		},
		handleTwoChange() {
			this.$refs.number3.focus();
		},
		handleThreeChange() {
			this.$refs.number4.focus();
		},
		handleChangeFourch() {
			this.validatePhone()
		},
		validatePhone() {
			this.fullscreenLoading = true
			this.errors = ""
			if (!this.formData.number1 || !this.formData.number2 || !this.formData.number3 || !this.formData.number4) {
				this.errors = "Please Enter authentication code"
				this.fullscreenLoading = false
				return
			}
			let authCode = this.formData.number1 + this.formData.number2 + this.formData.number3 + this.formData.number4
			let data = {
				code: authCode,
				phone: this.formDataNumber.phone
			}
			store.dispatch('auth/validatePhone', data)
				.then(response => {
					this.fullscreenLoading = false
					this.isOTP = false
					store.dispatch('auth/logout').then(res => {
						this.$router.push('/signin').catch((err) => {
							if (err.name !== "NavigationDuplicated") throw err;
						})
					})
					this.$notify({
						title: 'Success',
						message: "Your change number request has been processed successfully, Log in with the new number",
						type: 'success',
						duration: 7500
					});
				})
				.catch(err => {
					this.fullscreenLoading = false
					if (err.response.data === undefined) {
						this.errors = "An error occured try again."
					}
					if (err.response.status === 404) {
						this.errors = err.response.data.message
					}
					if (err.response.data) {
						this.errors = err.response.data.message
					}
				})
		},
		submitForm(formName) {
			this.errors = '';
			this.loading = true

			if (this.phoneData.isValid == false) {
				this.loading = false
				this.errors = 'Phone number is invalid';
				this.inputError = true;
				setTimeout(() => {
					this.inputError = false;
					this.errors = "";
				}, 5000);
				return;
			}
			else {
				this.formDataNumber.phone = `${this.phoneData.countryCallingCode}${this.phoneData.nationalNumber}`
				this.$refs[formName].validate((valid) => {
					if (valid && this.phoneData.nationalNumber.length >= 9) {
						store.dispatch('auth/updatePhone', this.formDataNumber)
							.then(response => {
								this.loading = false
								this.$notify({
									title: 'Success',
									message: response.data.message,
									type: 'success'
								});
								this.isOTP = true
							})
							.catch(err => {
								this.loading = false
								if (err.response.data === undefined) {
									this.errors = ['Network error try again']
								}
								if (err.response && err.response.status === 404) {
									this.errors = err.response.data.data.error;
								}
							})
					}
					else {
						this.loading = false;
						this.inputError = true;
						setTimeout(() => {
							this.inputError = false;
						}, 3000);
						return false;
					}
				});
			}
		},
	},
	mounted() {
		// Analytics
		this.$mixpanel.track("Change Number Page")
		if (this.$store.getters["auth/currentUser"].country === "CI") {
			this.$mixpanelFranco.track("Change Number Page");
		}
	}
}
</script>
<style scoped>
#account-settings {
	height: calc(100vh - 120px);
}

.profile_form {
	width: 385px;
	margin-left: 3em;
}

@media screen and (max-width: 500px) {
	.profile_form {
		width: 100%;
		;
		margin-left: 0px;
	}
}

.warning {
	color: red
}

.contains-new-input {
	width: 100%;
}

.is-form-item {
	display: block;
}

.input-model {
	min-width: 100% !important;
}
</style>
<template>
	<div class="col-md-12" v-loading.fullscreen.lock="fullscreenLoading">
		<div id="account-settings" class="mnotify-card">
			<div id="side-indicators-content" class="mnotify-card-header">
				<div class="profile-settings-section-header">
					<profilesettingsBtn />
					<div class="profile-settings-sub-header">
						<h3 class="mnotify-card-header-text pb-2 my-0">
							
							{{ $t("dashboard.account_settings.change_number.title") }}</h3>
						<p class="profile-description">
							{{ $t("dashboard.account_settings.change_number.sub_header") }}</p>
					</div>
				</div>
			</div>
			<div class="mnotify-card-body">
				<div class="row profile-settings-form" style="margin-top:2em;">
					<div class="profile_form">
						
						<div class="profile-inner">
							<el-form v-if="!isOTP" :model="formDataNumber" :rules="rules" ref="ruleForm"
								@submit.prevent.native="checkEnter">
								<div class="form_input_container">
									<el-form-item :label="$t('dashboard.account_settings.change_number.form.current_number.label')"
									prop="old_phone_number">
										<el-input type="text" autocomplete="off" :placeholder="$t('dashboard.account_settings.change_number.form.current_number.placeholder')"
											v-model="currentUser" disabled></el-input>
									</el-form-item>
								</div>
								<div class="form_input_container contains-new-input">
									<el-form-item :label="$t('dashboard.account_settings.change_number.form.new_number.placeholder')"
									prop="phone" class="is-form-item">
										<!-- <el-input type="text" autocomplete="off" placeholder="New Phone Number"
											v-model="formDataNumber.phone"></el-input> -->
										<vue-phone-number-input v-model="formattedPhone" :color="'#f79b31'" clearable
											size="lg" class="input-model" @update="handleCountryChange" fetch-country
											:preferred-countries="['GH', 'US',]" :required="true" ref="vueInput"
											:error="inputError"></vue-phone-number-input>
									</el-form-item>
								</div>
								<Alert v-if="errors" :message="errors" :type="type" />
								<div class="terms_and_conditons_container mt-5">
									<el-form-item>
										<el-button id="mNotify_form_submit_btn" :loading="loading" type="primary"
											@click="submitForm('ruleForm')">
											
											{{ $t("dashboard.account_settings.change_number.form.button") }}</el-button>
									</el-form-item>
								</div>
							</el-form>
							<el-form action="" v-else @submit.prevent.native="checkEnter">
								<p>Enter the OTP code sent to {{ formDataNumber.phone }}</p>
								<div class="pin__input__container">
									<div class="form_input_container veriy_account_input_text_padding_right verify_pin">
										<el-form-item prop="number1">
											<el-input type="text" autocomplete="false" @input="handleOneChange" autofocus
												ref="number1" maxlength="1" v-model="formData.number1"></el-input>
										</el-form-item>
									</div>
									<div class="form_input_container veriy_account_input_text_padding_right verify_pin">
										<el-form-item prop="number1">
											<el-input type="text" autocomplete="false" @input="handleTwoChange"
												ref="number2" maxlength="1" v-model="formData.number2"></el-input>
										</el-form-item>
									</div>
									<div class="form_input_container veriy_account_input_text_padding_right verify_pin">
										<el-form-item prop="number1">
											<el-input type="text" autocomplete="false" @input="handleThreeChange"
												ref="number3" maxlength="1" v-model="formData.number3"></el-input>
										</el-form-item>
									</div>
									<div class="form_input_container verify_pin">
										<el-form-item prop="number1">
											<el-input type="text" autocomplete="false" @input="handleChangeFourch"
												ref="number4" maxlength="1" v-model="formData.number4"></el-input>
										</el-form-item>
									</div>
								</div>
								<div v-if="errors">
									<Alert :message="errors" :type="type" />
								</div>
							</el-form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
